import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import "./body.css";
import Home from "./Pages/Home";
import Order from "./Pages/Order";
import Login from "./Pages/Login";
import Address from "./Pages/Address";
import OrderHistory from "./Pages/OrderHistory";
import Wallet from "./Pages/Wallet";
import AppContext from "./Context/Context";

function App() {
  return (
    <AppContext>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/order" element={<Order />} />
        <Route path="/address" element={<Address />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/wallet" element={<Wallet />} />
      </Routes>
    </AppContext>
  );
}

export default App;
