import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 50,
    }}
    spin
  />
);

function Spinner() {
  return (
    <div>
      <Spin
        style={{
          justifyContent: "center",
          display: "flex",
          height: "100vh",
          alignItems: "center",
        }}
        indicator={antIcon}
      />
    </div>
  );
}

export default Spinner;
