import React from "react";

function CustomPopup(props) {
  return (
    <div className="popup">
      <p>{props.message}</p>
    </div>
  );
}

export default CustomPopup;
