import { HomeFilled } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";

function GoToHome() {
  return (
    <Link
      to="/"
      className="goToHome"
      style={{
        position: "absolute",
        left: "10px",
        top: "8px",
        fontSize: "20px",
        color: "#db454d",
      }}
    >
      <HomeFilled />
    </Link>
  );
}

export default GoToHome;
