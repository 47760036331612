import React from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const Navbar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const signOut = () => {
    Cookies.remove("DBtoken");
    window.location.href = "/login";
  };
  return (
    <div
      style={{
        width: 256,
      }}
    >
      <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{
          marginBottom: 16,
        }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      {collapsed ? (
        <ul className="custom-ul">
          <Menu theme="dark" mode="inline">
            <Menu.Item key="2">
              <Link to="/order-history">
                Order History
                <p>
                  Let's you understand the orders delivered and <br /> it's
                  history from respect to date
                </p>
              </Link>
            </Menu.Item>
            <Menu.Item key="1">
              <Link to="/wallet">
                My Wallet
                <p>
                  The history of transactions on the orders you <br />
                  delivered
                </p>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="3">
              <Link to="/student">
                Notifications <p>Notifications from the App</p>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="3">
              <button
                onClick={() => {
                  signOut();
                }}
              >
                Logout
              </button>
              {/* <Link to="/login">Logout</Link> */}
            </Menu.Item>
          </Menu>
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

export default Navbar;
