import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { AccessToken, apiUrl } from "../../common/apis";
import Navbar from "../../Components/Navbar/Navbar";
import styles from "./home.module.css";
import Spinner from "../../Components/Spinner";
import MapButton from "../../Components/MapButton/MapButton";

function Home() {
  const [unassignedOrders, setUnassignedOrders] = useState();
  const [assignedOrders, setAssignedOrders] = useState();
  const [unassignedOrdersCount, setUnassignedOrdersCount] = useState(0);
  const [assignedOrdersCount, setAssignedOrdersCount] = useState(0);
  const [accepted, setAccepted] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!AccessToken) {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setLoading(true);
      if (window.pageYOffset === 0) {
        // getAssignedOrders();
        getUnassignedOrders();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getOrderTime = (time) => {
    const utcDateTime = new Date(time);
    const istDateTime = utcDateTime
      .toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      })
      .replace(/\//g, "-");
    return istDateTime;
  };

  // const getTimeDifference = (providedTime) => {
  //   let currentTime = new Date();
  //   const timeDifferenceInMillis =
  //     currentTime.getTime() - providedTime.getTime();
  //   const minutesDifference = Math.round(timeDifferenceInMillis / 60000);

  //   const output = `(${minutesDifference} minutes)`;
  //   return output;
  // };

  const getUnassignedOrders = () => {
    let config = {
      method: "GET",
      url: `${apiUrl}delivery/orders/?open_orders=true`,
      headers: { Authorization: AccessToken },
    };
    axios(config)
      .then(async (res) => {
        setUnassignedOrdersCount(res.data.count);
        setUnassignedOrders(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getAssignedOrders = () => {
    let config = {
      method: "get",
      url: `${apiUrl}delivery/orders/?on_going=true&page_wise=false`,
      headers: { Authorization: AccessToken },
    };

    axios(config)
      .then(async (res) => {
        setAssignedOrdersCount(res.data.length);
        // let assignedOrdersData = await res.data.map((orders) => {
        //   return {
        //     orderId: orders.id,
        //     customer: orders.user.first_name,
        //     customerPhone: orders.user.details.mobile,
        //     customerAddress: orders.address.address1,
        //     customerLandmark: orders.address.landmark,
        //     customerLatitude: orders.latitude,
        //     customerLongitude: orders.longitude,
        //     orderTime: getOrderTime(orders.payment.date),
        //     orderStatus: orders.status,
        //     deliveryNote: orders.delivery_note,
        //     deliveryPin: orders.order_pin,
        //     restaurant: orders.branch.name,
        //     restaurantPhone: orders.branch.mobile,
        //     restaurantAddress: orders.branch.address,
        //     orderItems: orders.issued_items,
        //     paymentType: orders.order_type,
        //     totalBill: orders.actual_amount_collected,
        //   };
        // });
        setAssignedOrders(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUnassignedOrders();
    getAssignedOrders();
  }, [accepted]);

  return (
    <div style={{ padding: "10px" }}>
      <div className={styles.headerWrapper}>
        <Navbar />
        {loading && <Spinner />} {/* Show the spinner when loading is true */}
        <h3 style={{ marginTop: "50px" }}>
          Hi, We found new <br />
          <span style={{ color: "#d9363e" }}>Orders</span> for you today
        </h3>
      </div>
      <div className={loading && styles.loadingIndicator}>
        <div className={styles.homeWrapper}>
          <div className={accepted ? styles.divActive : ""}>
            <button
              className={accepted ? styles.btnActive : ""}
              onClick={() => setAccepted(true)}
            >
              Accepted
              <span style={{ background: "rgb(79 138 52)" }}>
                {" " + assignedOrdersCount}
              </span>
            </button>
          </div>
          <div className={!accepted ? styles.divActive : ""}>
            <button
              className={!accepted ? styles.btnActive : ""}
              onClick={() => setAccepted(false)}
              style={{ width: "55%" }}
            >
              New <span>{" " + unassignedOrdersCount}</span>
            </button>
          </div>
        </div>
        {accepted ? (
          <div>
            {assignedOrders?.map((item) => {
              return (
                <Link
                  to="/order"
                  state={{ order: item, unassignedOrder: false }}
                >
                  <div className={styles.dataWrapper}>
                    <div className={styles.detailsTopWrapper}>
                      <div>
                        <h6>Order ID</h6>
                        <span>{`FDR ${item.id}`}</span>
                      </div>
                      <div>
                        <h6>Hotel Name</h6>
                        <span className={styles.restaurantNameSpan}>
                          {item.branch.name}
                        </span>
                      </div>
                      <div>
                        <h6>Order Placed:</h6>
                        <span>{getOrderTime(item.payment.date)}</span>
                      </div>
                    </div>
                    {item.delivery_note != "" && (
                      <div>
                        <h4>{item.delivery_note}</h4>
                      </div>
                    )}
                    <div className={styles.bottomDetailsWrapper}>
                      <div>
                        <MapButton
                          latitude={item.address.latitude}
                          longitude={item.address.longitude}
                        />
                        <h5>{`${item.address.address1}, ${item.address.land_mark}`}</h5>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        ) : (
          <div>
            {unassignedOrders &&
              unassignedOrders?.map((item) => {
                return (
                  <Link
                    to="/order"
                    state={{ order: item, unassignedOrder: true }}
                  >
                    <div className={styles.dataWrapper}>
                      <div className={styles.detailsTopWrapper}>
                        <div>
                          <h6>Order ID</h6>
                          <span>{`FDR ${item.id}`}</span>
                        </div>
                        <div>
                          <h6>Hotel Name</h6>
                          <span className={styles.restaurantNameSpan}>
                            {item.branch.name}
                          </span>
                        </div>
                        <div>
                          <h6>Order Placed:</h6>
                          <span>{getOrderTime(item.payment.date)}</span>
                        </div>
                      </div>
                      {item.delivery_note != "" && (
                        <div className={styles.deliveryNoteWrapper}>
                          <p>{item.delivery_note}</p>
                        </div>
                      )}
                      <div className={styles.bottomDetailsWrapper}>
                        <div>
                          <h6>Customer Location</h6>
                          <h5>{`${item.address.address1}, ${item.address.land_mark}`}</h5>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
