import React, { useEffect, useState } from "react";
import GoToHome from "../../Components/GoToHome";
import { AccessToken, apiUrl } from "../../common/apis";
import styles from "./wallet.module.css";
import { DatePicker, Space } from "antd";
import moment from "moment";
import axios from "axios";
import dayjs from "dayjs";

function Wallet() {
  const [ordersCount, setOrdersCount] = useState();
  const [orders, setOrders] = useState();
  const [nextPage, setNextPage] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const dateFormat = "DD-MM-YYYY";
  // const queryFormat = "YYYY-MM-DD 00:00:00";

  useEffect(() => {
    getOrderHistory();
  }, [fromDate, toDate]);

  const currentDate = new Date();
  const getOrderHistory = () => {
    let fromDateQuery = fromDate
      ? fromDate.format("YYYY-MM-DD 00:00:00")
      : moment(currentDate).subtract(7, "days").format("YYYY-MM-DD 00:00:00");
    let toDateQuery = toDate
      ? toDate.format("YYYY-MM-DD 00:00:00")
      : moment(currentDate).format("YYYY-MM-DD 00:00:00");

    let config = {
      method: "get",
      url: `${apiUrl}accounts/?limit=25&from_date=${fromDateQuery}&to_date=${toDateQuery}`,
      headers: { Authorization: AccessToken },
    };

    axios(config)
      .then((res) => {
        if (res.data.count > 0) {
          setOrdersCount(res.data.count);
          setOrders(res.data.results);
          setNextPage(res.data.next);
        }
      })
      .catch((err) => {});
  };

  const getOrderTime = (time) => {
    const utcDateTime = new Date(time);
    const istDateTime = utcDateTime
      .toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      })
      .replace(/\//g, "-");
    return istDateTime;
  };

  const loadMoreOrders = () => {
    let config = {
      method: "get",
      url: `${apiUrl}${nextPage.split("codedady.com/")[1]}`,
      headers: { Authorization: AccessToken },
    };

    axios(config)
      .then((res) => {
        if (res.data.count > 0) {
          setOrders((prev) => [...prev, ...res.data.results]);
          setNextPage(res.data.next);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="order-history">
      <GoToHome />
      <div style={{ margin: "10px auto" }}>
        <h4>Completed</h4>
        <hr />
      </div>
      <div className="top-items-wrapper">
        <h5 style={{ textAlign: "start" }} className="h5Top">
          {ordersCount || 0} items
        </h5>
        <h5
          style={{ color: "#d9363e", textAlign: "center" }}
          className="h5Top"
        >{`< Today >`}</h5>
        <h5 style={{ textAlign: "end" }} className="h5Top">
          Filter
        </h5>
      </div>
      <div style={{ display: "flex", margin: "10px 0" }}>
        <Space
          direction="vertical"
          size={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <DatePicker
            // defaultValue={dayjs(
            //   moment(currentDate).subtract(7, "days").format("YYYY-MM-DD")
            // )}
            format={dateFormat}
            onChange={(value, mode) => {
              setFromDate(value);
            }}
          />
          <DatePicker
            // defaultValue={dayjs(moment(currentDate).format("YYYY-MM-DD"))}
            format={dateFormat}
            onChange={(value, mode) => {
              setToDate(value);
            }}
          />
        </Space>
      </div>
      {orders?.map((orderItem) => {
        return (
          <div>
            <div className={styles.orderItemContainer}>
              <div>
                <h6>Date</h6>
                <h5>{getOrderTime(orderItem.date)}</h5>
              </div>
              <div>
                <h6>Order ID</h6>
                <h5>{`FDR ${orderItem.order}`}</h5>
              </div>
              <div style={{ width: "100%" }}>
                <h5>
                  Delivery Charge Earned :{" "}
                  <span style={{ color: "#db454d" }}>
                    {`  ${orderItem.delivery_charge}`}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        );
      })}
      {nextPage !== null && (
        <button
          className={styles.loadMoreBtn}
          onClick={() => {
            loadMoreOrders();
          }}
        >
          Load More
        </button>
      )}
    </div>
  );
}

export default Wallet;
