import axios from "axios";
import React, { useEffect, useState } from "react";
import { AccessToken, apiUrl } from "../../common/apis";
import "./login.module.css";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  useEffect(() => {
    if (AccessToken) {
      window.location.href = "/";
    }
  }, []);

  const signIn = () => {
    var data = new FormData();
    data.append("username", username);
    data.append("password", password);

    var config = {
      method: "post",
      url: `${apiUrl}user/login/`,
      data: data,
    };

    axios(config)
      .then((response) => {
        if (
          response.data.is_deliveryboy_blocked === true ||
          response.data.is_deliveryboy === false ||
          response.data.Status === false
        ) {
          toast.warning("Please contact the admin to login");
        } else {
          toast.success("Login success");
          const expirationDate = new Date();
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);
          Cookies.set("DBtoken", response.data.token, {
            expires: expirationDate,
          });
          window.location.href = "/";
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.warning("Wrong credentials", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  return (
    <div style={{ padding: "15px 25px" }} className="login-page">
      <ToastContainer />
      <h4 className="loginh4">WELCOME TO</h4>
      <h1 className="loginh1">FOODOOR DELIVERY PARTNER</h1>
      <h4 className="loginh4">Login to continue</h4>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          placeholder="Username"
          type="text"
          value={username}
          className="my-input-field"
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <input
          placeholder="Password"
          type="password"
          value={password}
          className="my-input-field"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      <button onClick={() => signIn()} className="loginBtn">
        Login
      </button>
    </div>
  );
}

export default Login;
