import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/service-worker.js")
//       .then((registration) => {
//         console.log("Service Worker registered: ", registration);
//         showInstallPrompt();
//       })
//       .catch((error) => {
//         console.error("Service Worker registration failed: ", error);
//       });
//   });
// }

// function showInstallPrompt() {
//   // Display your custom installation prompt here
//   // You can show a button or UI element to trigger the installation
//   <div
//     style={{
//       position: "absolute",
//       bottom: "10%",
//       left: "0px",
//       padding: "10px",
//     }}
//   >
//     <button>Install the App</button>
//   </div>;
// }

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
