import React from "react";
import { useLocation } from "react-router-dom";
import GoToHome from "../../Components/GoToHome";

function Address() {
  const location = useLocation();
  let props = location.state;
  return (
    <div className="address-view">
      <GoToHome />
      <h4>
        Address: <span>{props.address}</span>
      </h4>
      {!props.restaurant && (
        <h4>
          Landmark: <span>{props.landmark}</span>
        </h4>
      )}
      {props.orderStatus && (
        <h4>
          Phone: <span>{props.phone}</span>
        </h4>
      )}
    </div>
  );
}

export default Address;
