import React from "react";

const buttonStyles = {
  width: "100%",
  padding: "7px",
  fontWeight: "600",
  background: "#d9363e",
  border: "none",
  borderRadius: "5px",
  margin: "8px 0",
  color: "white",
};

function MapButton({ latitude, longitude }) {
  const openGoogleMaps = () => {
    // const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    let mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(mapUrl, "_blank");
  };

  return (
    <button onClick={openGoogleMaps} style={buttonStyles}>
      Go to Maps
    </button>
  );
}

export default MapButton;
