import { PhoneOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

import styles from "./order.module.css";
import { AccessToken, apiUrl } from "../../common/apis";
import GoToHome from "../../Components/GoToHome";
import CustomPopup from "../../Components/Popup";
import MapButton from "../../Components/MapButton/MapButton";

function Order() {
  const location = useLocation();
  const order = location.state.order;
  const unassignedOrder = location.state.unassignedOrder;
  let loopingNumber = 1;
  const [deliveryPin, setDeliveryPin] = useState();
  const [orderStatus, setOrderStatus] = useState(4);
  const [popupView, setPopupView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const assignOrder = () => {
    let data = new FormData();
    data.append("order_id", order.id);
    data.append("keyword", "open_orders");

    let config = {
      method: "post",
      url: `${apiUrl}delivery/orders/`,
      headers: { Authorization: AccessToken },
      data: data,
    };

    axios(config).then((res) => {
      toast.success(res.data.message);
      window.location.href = "/";
    });
  };

  const changeOrderStatus = (orderStatus, orderPin) => {
    setIsLoading(true);
    if (orderStatus === 4) {
      let data = new FormData();
      data.append("keyword", "change_status");
      data.append("order_id", order.id);
      data.append("status_id", "5");
      data.append("description", "out for delivery");

      let config = {
        method: "patch",
        url: `${apiUrl}order/details/`,
        headers: { Authorization: AccessToken },
        data: data,
      };

      axios(config)
        .then((res) => {
          if (!res.data.Status === "False") {
            toast.warning(res.data.Message);
          } else if (res.data.Status === true) {
            setOrderStatus(5);
            sessionStorage.setItem(
              "orderDetails",
              JSON.stringify({ order: order.id, status: 5 })
            );
            setIsLoading(false);
          } else {
            toast.error(res.data.Error);
            setIsLoading(false);
          }
        })
        .catch((errr) => {});
    } else if (orderStatus === 5) {
      if (orderPin === deliveryPin) {
        let data = new FormData();
        data.append("keyword", "change_status");
        data.append("order_id", Number(order.id));
        data.append("description", "order delivered");
        data.append("status_id", "6");
        data.append("pin", deliveryPin);

        let config = {
          method: "patch",
          url: `${apiUrl}order/details/`,
          headers: { Authorization: AccessToken },
          data: data,
        };

        axios(config).then((res) => {
          console.log("order status is 5", res.data);
          if (res.data.Status === true) {
            toast.success(res.data.Message);
            window.location.href = "/";
          } else {
            setIsLoading(false);
            toast.error(res.data.Error);
          }
        });
      } else {
        setIsLoading(false);
        setPopupView(true);
      }
    }
  };

  const getOrderTime = (time) => {
    const utcDateTime = new Date(time);
    const istDateTime = utcDateTime
      .toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      })
      .replace(/\//g, "-");
    return istDateTime;
  };

  useEffect(() => {
    let localOrder = JSON.parse(sessionStorage.getItem("orderDetails"));
    if (order?.id === localOrder?.order) {
      setOrderStatus(localOrder.status);
    } else {
      setOrderStatus(order.status.id);
    }
  }, []);

  useEffect(() => {
    if (popupView) {
      setTimeout(() => {
        setPopupView(false);
      }, 2000);
    }
  }, [popupView]);

  return (
    <div>
      <GoToHome />
      <ToastContainer />
      {popupView && <CustomPopup message={"Wrong pin entered"} />}
      {order && (
        <div className={styles.orderPageWrapper}>
          <div className={styles.itemsWrapper}>
            <div style={{ width: "100%" }}>
              <div className={styles.callBtnWrapper}>
                <div>
                  <h6>Date</h6>
                  <h5>{getOrderTime(order.payment.date)}</h5>
                </div>
                <div>
                  {!unassignedOrder && (
                    <a
                      href={`tel:${order.user.details.mobile}`}
                      className={styles.callCustomerBtn + " " + styles.callBtn}
                    >
                      Call Customer <PhoneOutlined />
                    </a>
                  )}
                </div>

                {console.log("order", order)}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {" "}
                  <h6>Order ID</h6>
                  <h5>{`FDR ${order.id}`}</h5>
                </div>
                <div>
                  <MapButton
                    latitude={order.address.latitude}
                    longitude={order.address.longitude}
                  />
                </div>
              </div>
              <div>
                <h6>Customer Name</h6>
                <h5>{order.user.first_name}</h5>
              </div>
            </div>
            <hr style={{ border: "1px dashed #d9363e", width: "100%" }} />
            <div>
              <div className="address-view">
                <p>
                  Address: <span>{order.address.address1}</span>
                </p>
                <p>
                  Landmark: <span>{order.address.landmark}</span>
                </p>
                <p>
                  Phone: <span>{order.user.mobile}</span>
                </p>
              </div>
            </div>
          </div>
          {order?.deliveryNote && (
            <div
              className="delivery-note-wrapper"
              style={{ borderRadius: "5px" }}
            >
              <h4>
                Delivery Note: <span>{order.deliveryNote}</span>
              </h4>
            </div>
          )}
          {!unassignedOrder && (
            <div className="items-listing">
              <h6>Order Items</h6>
              {order.issued_items.map((orderedItems) => {
                return (
                  <div>
                    <h5>
                      {loopingNumber++}.{" "}
                      <span style={{ color: "#D9363E" }}>
                        {orderedItems.item.name}
                      </span>
                      {` ${orderedItems.item_varient.name} (₹${orderedItems.rate}) x ${orderedItems.qty} = ${orderedItems.total}`}
                    </h5>
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.itemsWrapper}>
            <div className={styles.restaurantName} style={{ width: "100%" }}>
              <div className={styles.callBtnWrapper}>
                <div>
                  <h6>Restaurant</h6>
                  <h5>{order.branch.name}</h5>
                </div>
                <div>
                  <a
                    href={`tel:${order.branch.mobile}`}
                    className={styles.callBtn}
                  >
                    Call Restaurant <PhoneOutlined />
                  </a>
                </div>
              </div>
              <div>
                <h6>Mobile Number</h6>
                <h5>{order.branch.mobile}</h5>
              </div>
            </div>
            <hr style={{ border: "1px dashed #d9363e", width: "100%" }} />
            <div className={styles.restaurantDetailsWrapper}>
              <div className="address-view">
                <p>
                  Address: <span>{order.branch.address}</span>
                </p>
              </div>
            </div>
          </div>
          {orderStatus === 5 && (
            <div>
              <h3
                style={{
                  margin: "10px auto",
                  textAlign: "center",
                  color: "green",
                }}
              >
                Amount to Collect :{" "}
                {order.order_type === "COD" ? order.actual_amount_collected : 0}
              </h3>
              <input
                type="text"
                value={deliveryPin}
                className="my-input-field deliveryPin"
                onChange={(e) => setDeliveryPin(e.target.value)}
                placeholder="Enter the delivery pin"
              />
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {orderStatus === 2 ? (
              <button
                onClick={() => assignOrder()}
                className="order-status-btn"
              >
                Accept Order
              </button>
            ) : orderStatus === 4 ? (
              <button
                onClick={() => changeOrderStatus(order.status.id)}
                className="order-status-btn"
                disabled={isLoading}
              >
                Out for Delivery
              </button>
            ) : (
              <button
                onClick={() =>
                  changeOrderStatus(orderStatus, order.user.details.order_pin)
                }
                className="order-status-btn"
                disabled={isLoading}
              >
                Complete Delivery
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default Order;
